import React, { useState } from 'react'
import { Card } from 'antd'

interface ModuleCardProps {
  title: string
  description: string
  img: string
}

function ModuleCard({ title, description, img }: ModuleCardProps) {
  const [hovered, setHovered] = useState(false)
  const { Meta } = Card
  return (
    <Card
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      hoverable
      className="module"
      style={{
        width: 230,
        height: 230,
        borderRadius: 0,
        border: '1px solid #055a5b',
      }}
    >
      {!hovered && (
        <Meta
          title={
            <span
              style={{
                color: '#055a5b',
                fontFamily: 'Ubuntu, sans-serif',
              }}
            >
              {title}
            </span>
          }
          description={description}
          style={{
            fontFamily: 'Ubuntu, sans-serif',
          }}
        />
      )}
      {hovered && (
        <img
          alt={title}
          width="100%"
          height="100%"
          src={'../assets/'.concat(img, '.png')}
          style={{
            borderRadius: 10,
            maxHeight: 400,
          }}
        />
      )}
    </Card>
  )
}
export default ModuleCard

import React, { useState } from 'react'
import { Progress, Radio, Layout, Col, Row, Typography, Grid } from 'antd'

// @ts-ignore
import { Helmet } from 'react-helmet'
import './quizz.css'
import RadarChart from './Radar'
import { questions, findMostCommonElement } from './QuizzQuestions'

import ProfileCardExtend from '../components/ProfileCardExtend'
function Quizz() {
  const { Title } = Typography
  const [showResult, setShowResult] = useState(false)
  const { useBreakpoint } = Grid
  const [answers, setAnswers] = useState<Number[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [profile, setProfile] = useState<string | undefined>('')
  const breakpoint = useBreakpoint()
  const anyBreakpoint =
    breakpoint.xl || breakpoint.xxl || breakpoint.lg || breakpoint.md
      ? true
      : false
  const { Content } = Layout
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const handleSubmit = (e: any, tags: any) => {
    setAnswers([...answers, e.target.value])
    tags.map((tag: any) => setSelectedTags([...selectedTags, tag]))
    if (currentQuestion === questions.length - 1) {
      let result = findMostCommonElement(selectedTags)
      setProfile(result)
      setShowResult(true)
    } else {
      setCurrentQuestion(currentQuestion + 1)
    }
  }

  return (
    <Layout>
      <div>
        <Helmet>
          <title>Discover Your Perfect Learning Path in Blockchain</title>
          <meta
            name="description"
            content="Discover Your Perfect Learning Path in Blockchain"
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content>
        <Progress
          percent={(currentQuestion + 1) * (100 / questions.length)}
          showInfo={false}
          strokeColor="#d06d66"
          style={{
            backgroundColor: '#f9dcc4',
            padding: 0,
            margin: 0,
          }}
        />
        <Row style={{ padding: 0, height: '70vh' }}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#f9dcc4',
              paddingLeft: anyBreakpoint ? 50 : 20,
              paddingRight: 20,
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={3}
                  style={{
                    fontWeight: 'lighter',
                    color: 'balck',
                  }}
                >
                  Discover your learning path by Kosmos
                </Title>
              </Col>
            </Row>
            {showResult && (
              <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Title
                        level={2}
                        style={{
                          fontWeight: 'normal',
                          color: 'black',
                        }}
                        className="quizz-question"
                      >
                        Your Learning Path Is ...
                      </Title>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {profile && profile === 'Hero' && (
                        <ProfileCardExtend
                          title="Hero"
                          headline="Master Blockchain Development: Build Cutting-Edge Apps"
                          description="This learning path is designed for software engineers and developers who want to take their skills to the next level by mastering blockchain development. You'll learn how to build blockchain-based applications, integrate blockchain into existing software systems, and take advantage of cutting-edge tools and technologies. "
                          level="Advanced"
                        />
                      )}
                      {profile && profile === 'Mediator' && (
                        <ProfileCardExtend
                          title="Mediator"
                          headline="Learn About Blockchain for Non-Tech Professionals"
                          description="This learning path is designed for  business professionals, and others who want to understand the potential of blockchain technology and the web3 ecosystem. You'll learn the basics of blockchain and how it works and explore the various use cases for blockchain in different industries."
                          level="Beginner Friendly"
                        />
                      )}
                      {profile && profile === 'Adventurer' && (
                        <ProfileCardExtend
                          title="Adventurer"
                          headline="Discover the World of Blockchain: A Beginner's Guide"
                          description="This learning path is designed for individuals who are new to the world of blockchain and want to gain a foundational understanding of how it works. You'll start with the basics and gradually build your knowledge and skills, with a focus on real-world examples and practical applications. "
                          level="Beginner Friendly"
                        />
                      )}

                      {profile && profile === 'Virtuoso' && (
                        <ProfileCardExtend
                          title="Virtuoso"
                          headline="Unlock the Power of Blockchain for Your Business: A Practical Guide"
                          description="This learning path is designed for entrepreneurs who want to unlock the power of blockchain for their businesses. You'll learn how to build blockchain-powered products and services, disrupt existing industries, and create new business models that leverage the unique properties of blockchain technology."
                          level="Intermediate"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {!showResult && questions.length > 0 && (
              <Row style={{ paddingTop: 20, paddingBottom: 40 }}>
                <Col span={24}>
                  <Title
                    level={2}
                    style={{
                      fontFamily: "'Ubuntu', sans-serif",
                      fontWeight: 'normal',
                      color: 'black',
                    }}
                    className="quizz-question"
                  >
                    {questions[currentQuestion].question}
                  </Title>
                  <Radio.Group
                    buttonStyle="solid"
                    style={{
                      width: '100%',
                    }}
                  >
                    {questions[currentQuestion].answers.map((option, index) => (
                      <Row style={{ paddingTop: 20 }} key={index}>
                        <Col span={24} style={{ width: '100%' }}>
                          <Radio.Button
                            className="radio-button-hover"
                            style={{
                              backgroundColor: '#fef8ef',
                              maxWidth: 400,
                              width: '100%',
                              minHeight: 60,
                              height: '100%',
                              textAlign: 'left',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: 30,
                              fontFamily: "'Ubuntu', sans-serif",
                              fontSize: 15,
                              color: 'black',
                              border: 'none',
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                            key={index}
                            value={option.id}
                            onChange={(e) => handleSubmit(e, option.tags)}
                          >
                            {option.answer}
                          </Radio.Button>
                        </Col>
                      </Row>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2',
            }}
          >
            <RadarChart />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default Quizz

import React from 'react'
import { Card, Row, Col, Typography, Button } from 'antd'

interface PriceCardProps {
  title: string
  description: string
  price: string
  link: string
  color: string
}

function PriceCard({ title, description, price, link, color }: PriceCardProps) {
  const { Meta } = Card
  const { Title } = Typography
  return (
    <Card
      className="price"
      style={{
        width: 230,
        height: 230,
        borderRadius: '0% 25% 0% 0% / 25% 26% 25% 25%',
        border: '1px solid black',
        backgroundColor: color,
      }}
    >
      <Meta
        title={
          <Title
            level={3}
            style={{
              color: '#055a5b',
              fontFamily: 'Ubuntu, sans-serif',
            }}
          >
            {title}
          </Title>
        }
        style={{
          fontFamily: 'Ubuntu, sans-serif',
        }}
      />
      <Row
        style={{
          height: '100%',
        }}
      >
        <Col span={24}>
          <Row>
            <Col span={24}>
              <span
                style={{
                  fontFamily: 'Ubuntu, sans-serif',
                  color: 'black',
                  fontSize: 30,
                  fontWeight: 'bold',
                }}
              >
                {price}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <span
                style={{
                  fontFamily: 'Ubuntu, sans-serif',
                  color: 'black',
                }}
              >
                {description}
              </span>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: 10,
            }}
          >
            <Col span={24}>
              <Button
                className="header-button"
                href={link}
                size="large"
                style={{
                  backgroundColor: '#055a5b',
                  fontFamily: "'Ubuntu', sans-serif",
                  borderRadius: 0,
                  color: 'white',
                }}
              >
                Join Now
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default PriceCard

import React from 'react'

import { Layout, Row, Col, Space } from 'antd'

import * as echarts from 'echarts/core';
import { TreemapChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import vintage from '../vintage.json'
import {MyTreeMap} from '../components/Charts';
import {  blockchainMapData } from '../data/Course'
// Register the necessary components
echarts.use([TitleComponent, TooltipComponent, TreemapChart, CanvasRenderer]);
// load theme data
echarts.registerTheme('vintage', vintage)

const TreeChart = () => {
  const { Content } = Layout
  return (
    <React.Fragment>
    <Layout>
      <Content>
        <Row
          style={{ backgroundColor: '#fef8ef', paddingTop: 10 }}
          justify="center"
        >
          <Col
            span={24}
            style={{
              textAlign: 'center',
            }}
          >
            <Space>
       
            </Space>
          </Col>
        </Row>
        <Row style={{ backgroundColor: '#fef8ef' }} justify='center'  align="middle">
          <Col span={24} style={{alignItems:'center' , textAlign:'center' , justifyContent:'center'}} >
            <div
              style={{
                paddingTop: '20px',
                height: '70vh',
                width:'100%',
                backgroundColor: '#fef8ef',
           
              }}
            >
              <MyTreeMap title='The Blockchain Universe: Mapping Main Concepts and Technologies' blockchainMapData={blockchainMapData} />
            
            </div>
          </Col>
        </Row>
       <Row justify='center'>
        <Col span={18}>
          <p style={{
            fontSize: '20px',
          }}>

            Welcome to Kosmos TreeMap, where i showcase the main concepts and technologies of the blockchain world in an easy-to-understand visual format. The treemap is an attempt to provides a comprehensive overview of the blockchain landscape.</p>

<p style={{
            fontSize: '20px',
          }}>The treemap is designed to help you explore the various concepts and technologies in the blockchain space, from cryptocurrencies and smart contracts to decentralized applications and consensus mechanisms. By clicking on any of the rectangles, you can drill down into more specific subcategories and learn more about the underlying technologies and trends.</p>

<p style={{
            fontSize: '20px',
          }}>I hope that this treemap provides a useful resource for anyone looking to gain a deeper understanding of the blockchain world. Whether you are a blockchain enthusiast or a curious newcomer.</p>

<p style={{
            fontSize: '20px',
          }}>Please check the version and date in the footer to stay informed about the latest updates, as my blockchain tree map will be continually improved over time to provide a visual representation of the main concepts and technologies.
          </p>
  
        </Col>
       </Row>
      </Content>
    </Layout>
    </React.Fragment>
  )
}

export default TreeChart

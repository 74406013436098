import ReactEcharts from 'echarts-for-react'
import React from 'react'

const RadarChart = () => {
  const option = {
    backgroundColor: '#fef8ef',
    title: {
      text: 'Kosmos Learning Path',
      left: 'center',
      textStyle: {
        color: '#363535',
        fontFamily: 'Ubuntu, sans-serif',
      },
    },
    legend: {
      bottom: 5,
      data: ['Hero', 'Vituoso', 'Mediator', 'Adventurer'],
      itemGap: 20,
      textStyle: {
        color: 'black',
        fontSize: 14,
      },
      selectedMode: 'multiple',
    },

    radar: {
      indicator: [
        { name: 'Blockchain', max: 100 },
        { name: 'Digital Assets', max: 100 },
        { name: 'TokenEconomy', max: 100 },
        { name: 'DeFi', max: 100 },
        { name: 'Community', max: 100 },
        { name: 'Product', max: 100 },
      ],
      shape: 'circle',
      name: {
        textStyle: {
          color: '#fff',
          backgroundColor: '#919e8a',
          borderRadius: 3,
          padding: [3, 5],
        },
      },
      splitLine: {
        lineStyle: {
          color: '#999',
          type: 'dashed',
        },
      },
      splitArea: {
        show: true,
      },
      axisLine: {
        lineStyle: {
          color: '#999',
          type: 'solid',
        },
      },
    },
    series: [
      {
        name: 'Hero',
        type: 'radar',
        color: ['#d77c7c'],
        // set the line style for the series
        lineStyle: {
          width: 1,
          type: 'solid',
        },
        data: [
          {
            value: [60, 73, 85, 40, 60],
            name: 'Hero',
          },
        ],
        areaStyle: {
          opacity: 0.3,
        },
      },
      {
        name: 'Vituoso',
        type: 'radar',
        color: ['#d7ab81'],
        // set the line style for the series
        lineStyle: {
          width: 2,
          type: 'solid',
        },
        data: [
          {
            value: [85, 60, 70, 50, 85],
            name: 'Vituoso',
          },
        ],
        areaStyle: {
          opacity: 0.3,
        },
      },
      {
        name: 'Mediator',
        type: 'radar',
        color: ['#6e7074'],
        // set the line style for the series
        lineStyle: {
          width: 1,
          type: 'solid',
        },
        data: [
          {
            value: [50, 85, 60, 70, 80],
            name: 'Mediator',
          },
        ],
        areaStyle: {
          opacity: 0.3,
        },
      },
      {
        name: 'Adventurer',
        type: 'radar',

        color: ['#919e8a'],
        // set the line style for the series
        lineStyle: {
          width: 1,
          type: 'solid',
        },
        data: [
          {
            value: [70, 80, 65, 75, 70],
            name: 'Adventurer',
          },
        ],
        areaStyle: {
          opacity: 0.3,
        },
      },
    ],
  }

  return (
    <ReactEcharts option={option} style={{ height: '100%', width: '100%' }} />
  )
}

export default RadarChart

import React from "react";
import { Row, Col, Typography, Space } from "antd";
import PriceCard from "./PriceCard";
import "../App.css";

function PricingSection() {
  const { Title, Paragraph } = Typography;
  const link = "https://tripetto.app/run/1EHA0P341I";
  return (
    <Row
      id="enrollement"
      justify="center"
      style={{
        paddingTop: 20,
      }}
    >
      <Col span={24}>
        <Row>
          <Col
            span={24}
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={4}
                  style={{
                    margin: 0,
                    fontFamily: "Ubuntu",
                    color: "#055a5b",
                    fontWeight: "lighter",
                  }}
                >
                  How much it cost to become a Blockchain Pro
                </Title>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    margin: 0,
                    fontFamily: "Ubuntu",
                    color: "#055a5b",
                    fontWeight: "bold",
                  }}
                >
                  One time payment
                </Title>
              </Col>
            </Row>

            <Row
              style={{
                paddingTop: 20,
              }}
            >
              <Col span={24}>
                <Paragraph>
                  For now the prices are adapted to your profile, also you will
                  get
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }} justify="center" align="middle">
          <Space size="large" className="price-overflow">
            <Col>
              <PriceCard
                title="Early Bird"
                description="Your are the best"
                price="$ 199"
                link={link}
                color="#f7e4df"
              />
            </Col>
            <Col>
              <PriceCard
                title="Adventurer"
                description="Your are the best"
                price="$ 299"
                link={link}
                color="#dde8eb"
              />
            </Col>
            <Col>
              <PriceCard
                title="Mediator"
                description="Your are the best"
                price="$ 299"
                link={link}
                color="#fbf0c4"
              />
            </Col>
            <Col>
              <PriceCard
                title="Virtuoso"
                description="Your are the best"
                price="$ 399"
                link={link}
                color="#e7e6eb"
              />
            </Col>
            <Col>
              <PriceCard
                title="Hero"
                description="Your are the best"
                price="$ 499"
                link={link}
                color="#ccd5ae"
              />
            </Col>
          </Space>
        </Row>
      </Col>
      <Row
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Col span={24}>
          <Title
            level={3}
            style={{
              margin: 0,
              fontFamily: "Ubuntu",
              color: "#055a5b",
              fontWeight: "bold",
            }}
          >
            $125 000 is the Average Salary for Blockchain Software Engineer
            2022*
          </Title>
        </Col>
      </Row>
    </Row>
  );
}
export default PricingSection;

import React from "react";
import { Row, Col, Typography, Space, Button } from "antd";
import ProfileCard from "./ProfileCard";
import "../App.css";
import { useNavigate } from "react-router-dom";

function WhoSection() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/kosmos-course/");
  };

  return (
    <Row
      justify="center"
      style={{
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Col span={24}>
        <Row>
          <Col
            span={24}
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Title
              level={4}
              style={{
                margin: 0,
                fontFamily: "Ubuntu",
                color: "white",
                fontWeight: "lighter",
              }}
            >
              Our Learning Path
            </Title>
            <Title
              level={2}
              style={{
                margin: 0,
                fontFamily: "Ubuntu",
                color: "white",
              }}
            >
              For Who ?
            </Title>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 20,
          }}
        >
          <Col
            span={24}
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div className="profile-container">
              <div className="profile-card">
                <ProfileCard
                  title="Adventurer"
                  headline="Discover the World of Blockchain: A Beginner's Guide"
                  description="This learning path is designed for individuals who are new to the world of blockchain and want to gain a foundational understanding of how it works. You'll start with the basics and gradually build your knowledge and skills, with a focus on real-world examples and practical applications. "
                  level="Beginner Friendly"
                />
              </div>

              <div className="profile-card">
                <ProfileCard
                  title="Mediator"
                  headline="Learn About Blockchain for Non-Tech Professionals"
                  description="This learning path is designed for  business professionals, and others who want to understand the potential of blockchain technology and the web3 ecosystem. You'll learn the basics of blockchain and how it works and explore the various use cases for blockchain in different industries."
                  level="Beginner Friendly"
                />
              </div>
              <div className="profile-card">
                <ProfileCard
                  title="Virtuoso"
                  headline="Unlock the Power of Blockchain for Your Business: A Practical Guide"
                  description="This learning path is designed for entrepreneurs who want to unlock the power of blockchain for their businesses. You'll learn how to build blockchain-powered products and services, disrupt existing industries, and create new business models that leverage the unique properties of blockchain technology."
                  level="Intermediate"
                />
              </div>
              <div className="profile-card">
                <ProfileCard
                  title="Hero"
                  headline="Master Blockchain Development: Build Cutting-Edge Apps"
                  description="This learning path is designed for software engineers and developers who want to take their skills to the next level by mastering blockchain development. You'll learn how to build blockchain-based applications, integrate blockchain into existing software systems, and take advantage of cutting-edge tools and technologies. "
                  level="Advanced"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p
              style={{
                color: "white",
              }}
            >
              * the names of different paths are inspired by MBTI profile names
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button
              className="header-button"
              onClick={handleClick}
              size="large"
              style={{
                backgroundColor: "#f9dcc4",
                fontFamily: "'Ubuntu', sans-serif",
                borderRadius: 0,
              }}
            >
              Discover Kosmos
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default WhoSection;

import { Layout, Row, Col, Typography, Divider } from 'antd'
import { InstagramOutlined, TwitterOutlined } from '@ant-design/icons'
import React from 'react'
const { Footer } = Layout
const { Text } = Typography

function AppFooter() {
  return (
    <Footer style={{ backgroundColor: '#160c11', color: 'white' }}>
      <Row gutter={[16, 16]}>
        <Col span={24} md={6}>
          <Text style={{ color: '#fff', fontSize: 20, fontWeight: 'bold' }}>
            Kosmos
          </Text>
          <br />
          <br />
          <Text style={{ color: 'white' }}>124 rue castagnary</Text>
          <br />
          <Text style={{ color: 'white' }}>Paris, 75015, France</Text>
          <br />
          <Text style={{ color: 'white' }}>copilot@kosmoschain.com</Text>
        </Col>
        <Col span={24} md={6}>
          <Text style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Navigation
          </Text>
          <br />
          <br />
          <Text style={{ color: 'white' }}>Home</Text>
          <br />
          <Text style={{ color: 'white' }}>About Us</Text>
          <br />
          <Text style={{ color: 'white' }}>Contact Us</Text>
          <br />
          <Text style={{ color: 'white' }}>FAQ</Text>
        </Col>
        <Col span={24} md={6}>
          <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }}>
            Follow
          </Text>
          <br />
          <br />
          <a href="https://bento.me/kosmos">
            <InstagramOutlined style={{ fontSize: 24, color: '#fff' }} />
          </a>
          <a href="https://bento.me/kosmos">
            <TwitterOutlined style={{ fontSize: 24, color: '#fff' }} />
          </a>
        </Col>
        <Col span={24} md={6}>
          <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }}>
            Legal
          </Text>
          <br />
          <br />
          <Text style={{ color: 'white' }}>
            <a href="/terme-of-use">Terms of Use</a>
          </Text>
          <br />
          <Text style={{ color: 'white' }}>
            <a href="/terme-of-use">Privacy Policy</a>
          </Text>
          <br />
        </Col>
      </Row>
      <Divider style={{ backgroundColor: '#fff' }} />
      <Text type="secondary" style={{ color: '#fff' }}>
        © {new Date().getFullYear()} KosmosChain. All Rights Reserved. Made with
        Love
      </Text>
    </Footer>
  )
}

export default AppFooter

import React from "react";
import { Row, Col, Typography, Button, Space, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./hero.css";
import "./animation.js";

function HeroSection() {
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const anyBreakpoint =
    breakpoint.xl || breakpoint.xxl || breakpoint.lg || breakpoint.md
      ? true
      : false;
  const { Title } = Typography;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/kosmos-course/");
  };
  return (
    <Row
      style={{
        paddingTop: 50,
        paddingBottom: 20,
      }}
      gutter={12}
    >
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <div
          className="card"
          style={{
            width: anyBreakpoint ? 400 : 300,
            height: anyBreakpoint ? 400 : 300,
          }}
        ></div>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{
                color: "#055a5b",
                fontWeight: 700,
              }}
            >
              Blockchain Odyssey: A Journey through the Decentralized Kosmos -
              An Online Course
            </Title>
          </Col>
        </Row>
        {anyBreakpoint && (
          <Row>
            {" "}
            <Col span={24}>
              <Row>
                <Col>
                  <h1 className="dynamic">
                    <a
                      href="ok"
                      className="typewrite"
                      data-period="2000"
                      data-type='[ "For Entrepreneurs.", "For Programmers.", "For Products.", "For Marketers." , "For Beginners" ]'
                    >
                      <span className="wrap"></span>
                    </a>
                  </h1>
                </Col>
              </Row>
            </Col>{" "}
          </Row>
        )}

        <Row>
          {" "}
          <Col span={24}>
            <Row>
              <Col>
                <p
                  style={{
                    fontSize: "1.5em",
                    lineHeight: 1.6,
                    fontFamily: "Ubuntu",
                  }}
                >
                  This course is designed for beginners and intermediate or
                  advanced learners looking to expand their knowledge and skills
                  in the world of blockchain and web3.
                </p>
              </Col>
            </Row>
          </Col>{" "}
        </Row>
        <Row
          style={{
            paddingTop: 5,
          }}
        >
          <Space>
            <Button
              className="header-button"
              size="large"
              href="#enrollement"
              style={{
                backgroundColor: "#055a5b",
                color: "white",
                fontFamily: "'Ubuntu', sans-serif",
                fontWeight: 700,
                borderRadius: 0,
              }}
            >
              Start your journey
            </Button>
            {anyBreakpoint && (
              <Button
                onClick={handleClick}
                className="header-button"
                size="large"
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "'Ubuntu', sans-serif",
                  borderRadius: 0,
                }}
              >
                👀 Kosmos
              </Button>
            )}
          </Space>
        </Row>
      </Col>
    </Row>
  );
}

export default HeroSection;

import React, { useState } from 'react'
import { CompassFilled } from '@ant-design/icons'

import { Card, Typography } from 'antd'

interface ProfileCardProp {
  title: string
  headline: string
  description: string
  level: string
}

function ProfileCardExtend({
  title,
  description,
  headline,
  level,
}: ProfileCardProp) {
  const { Meta } = Card
  const [hovered, setHovered] = useState(false)
  const { Paragraph } = Typography
  return (
    <Card
      hoverable
      title={title}
      className="profile"
      headStyle={{
        textAlign: 'left',
        backgroundColor: '#d2716a',
        borderRadius: 0,
        fontFamily: 'Ubuntu',
        fontWeight: 'bold',
        color: 'white',
      }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '300px',
      }}
      style={{
        width: 320,
        maxHeight: 400,
        minHeight: 410,
        borderRadius: 0,
      }}
      actions={[
        <div
          style={{
            textAlign: 'left',
            color: '#606c38',
            paddingLeft: 20,
          }}
        >
          <CompassFilled key="setting" />
          <span style={{ marginLeft: 5 }}>{level}</span>
        </div>,
      ]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!hovered && (
        <Meta
          style={{
            textAlign: 'left',
            height: '100%',
            whiteSpace: 'normal',
          }}
          title={<div style={{ whiteSpace: 'normal' }}>{headline}</div>}
          description={<Paragraph> {description} </Paragraph>}
        />
      )}
      {hovered && (
        <img
          alt={title}
          width="100%"
          height="100%"
          src={'../assets/'.concat(title, '.png')}
          style={{
            borderRadius: 10,
            maxHeight: 400,
          }}
        />
      )}
    </Card>
  )
}
export default ProfileCardExtend

import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { TreemapChart } from "echarts/charts";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import vintage from "../vintage.json";

// Register the necessary components
echarts.use([TitleComponent, TooltipComponent, TreemapChart, CanvasRenderer]);
// load theme data
echarts.registerTheme("vintage", vintage);

interface MyTreeMapProps {
  blockchainMapData: any;
  title: string;
}

export const MyTreeMap = ({ blockchainMapData, title }: MyTreeMapProps) => {
  function getLevelOption() {
    return [
      {
        itemStyle: {
          borderColor: "#777",
          borderWidth: 0,
          gapWidth: 1,
        },
        upperLabel: {
          show: false,
        },
      },
      {
        itemStyle: {
          borderColor: "#555",
          borderWidth: 5,
          gapWidth: 1,
        },
        emphasis: {
          itemStyle: {
            borderColor: "#ddd",
          },
        },
      },
      {
        colorSaturation: [0.35, 0.5],
        itemStyle: {
          borderWidth: 5,
          gapWidth: 1,
          borderColorSaturation: 0.6,
        },
      },
    ];
  }

  const optionTreeMap = {
    title: {
      text: title,
      left: "center",
    },
    tooltip: {
      show: true,
      formatter: function (params: any) {
        const description = params.data.description;
        if (description && description.length > 50) {
          // if the description is longer than 50 characters, split it into multiple lines
          const words = description.split(" ");
          const lines = [];
          let currentLine = "";
          for (let i = 0; i < words.length; i++) {
            if ((currentLine + " " + words[i]).length > 50) {
              lines.push(currentLine);
              currentLine = words[i];
            } else {
              currentLine += " " + words[i];
            }
          }
          lines.push(currentLine);
          return params.name + "<br>" + lines.join("<br>");
        } else {
          // if the description is shorter than 50 characters, just show it on one line
          return description ? `${params.name}<br>${description}` : params.name;
        }
      },
    },
    series: [
      {
        type: "treemap",
        name: title,
        cliquable: true,
        data: blockchainMapData,
        levels: getLevelOption(),
        leafDepth: 2,
        sort: "desc",
        label: {
          fontSize: 14,
          show: true,
        },
        upperLabel: {
          textBorderColor: "transparent",
          textBorderWidth: 0,
          color: "white",
          size: 14,
          show: true,
          height: 30,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={optionTreeMap}
      style={{
        height: "100%",
      }}
      theme="vintage"
      lazyUpdate={true}
    />
  );
};

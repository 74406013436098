import React from 'react'
import { Row, Col, Typography, Button } from 'antd'
import { useNavigate } from 'react-router-dom'

function QuizzSection() {
  const { Title, Paragraph } = Typography
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/quizz/')
  }
  return (
    <Row
      justify="end"
      align="middle"
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        height: 600,
      }}
    >
      <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <Title
          level={2}
          style={{
            fontFamily: 'Ubuntu, sans-serif',
            fontWeight: 700,
            color: 'white',
          }}
        >
          Not Sure Where to Start ?
        </Title>
        <Paragraph
          ellipsis={{
            rows: 2,
          }}
          style={{
            color: 'white',
            fontFamily: 'Ubuntu, sans-serif',
            fontSize: 16,
          }}
        >
          Our quiz is designed to help you find the perfect fit for your
          interests and skills in the blockchain world.
        </Paragraph>
        <Button
          onClick={handleClick}
          className="header-button"
          size="large"
          style={{
            backgroundColor: '#f9dcc4',
            fontFamily: "'Ubuntu', sans-serif",
            borderRadius: 0,
          }}
        >
          Take Kosmos Quizz
        </Button>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        xxl={12}
        style={{
          textAlign: 'center',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            maxHeight: 400,
            maxWidth: 400,
          }}
        >
          <img
            src="../assets/quizz.png"
            width="100%"
            height="100%"
            alt="take the quizz"
          />
        </div>
      </Col>
    </Row>
  )
}

export default QuizzSection

import React from 'react'
//@ts-ignore
import { Helmet } from 'react-helmet'

const Sitemap = () => {
  return (
    <Helmet>
      <title>Kosmos Sitemap</title>
      <link rel="stylesheet" href="/sitemap.xml" type="application/xml" />
    </Helmet>
  )
}

export default Sitemap

import React, { useState, useEffect } from 'react'
import { Layout, Typography, Row, Col } from 'antd'
import ReactMarkdown from 'react-markdown'
import '../App.css'
// import the content file
import tos from '../content/tos.md'

function TosPage() {
  const [tosText, setTosText] = useState('')
  const { Content } = Layout
  const { Title } = Typography
  const { Paragraph } = Typography

  // fetch tos data
  useEffect(() => {
    fetch(tos)
      .then((res) => res.text())
      .then((text) => setTosText(text))
  })
  return (
    <Layout>
      <Content>
        <Row
          className="active-row"
          style={{
            paddingTop: 50,
          }}
        >
          <Col span={18}>
            <Row>
              <Col span={24}>
                <Title level={1} style={{ fontFamily: 'Ubuntu, sans-serif' }}>
                  {' '}
                  Terme of Use
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    paddingTop: 15,
                    color: 'black',
                    fontSize: 15,
                  }}
                >
                  <ReactMarkdown children={tosText} />
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default TosPage

import React, { useState, useEffect } from 'react'
import { Layout, Typography, Row, Col } from 'antd'
import ReactMarkdown from 'react-markdown'
import '../App.css'
// import the content file
import privacy from '../content/privacy.md'

function PrivacyPage() {
  const [privacyText, setPrivacyText] = useState('')
  const { Content } = Layout
  const { Title } = Typography
  const { Paragraph } = Typography

  // fetch tos data
  useEffect(() => {
    fetch(privacy)
      .then((res) => res.text())
      .then((text) => setPrivacyText(text))
  })
  return (
    <Layout>
      <Content>
        <Row
          className="active-row"
          style={{
            paddingTop: 50,
          }}
        >
          <Col span={18}>
            <Row>
              <Col span={24}>
                <Title level={1} style={{ fontFamily: 'Ubuntu, sans-serif' }}>
                  {' '}
                  Privacy Policy
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    paddingTop: 15,
                    color: 'black',
                    fontSize: 15,
                  }}
                >
                  <ReactMarkdown children={privacyText} />
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default PrivacyPage

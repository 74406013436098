import React, { useState, useEffect } from 'react'
import { Layout, Menu, Button } from 'antd'
import './header.css'

const { Header } = Layout

const HeaderComponent: React.FC = () => {
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY < 50
      if (scrollCheck !== isTop) {
        setIsTop(scrollCheck)
      }
    })
  })
  return (
    <Header className={`header ${isTop ? '' : 'white'}`} style={{}}>
      <div
        style={{
          maxWidth: 100,
          maxHeight: 100,
          paddingRight: 20,
          transition: 'background-color 0.3s ease-in-out',
        }}
      >
        <a href="/">
          <img
            src="../assets/kosmos-logo.png"
            alt="Komsos Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </a>
      </div>
      <Menu
        theme="light"
        mode="horizontal"
        defaultSelectedKeys={['1']}
        style={{
          backgroundColor: 'transparent',
          width: '100%',
          borderBottom: '0px solid transparent',
          fontFamily: 'Ubuntu, sans-serif',
        }}
      >
        <Menu.Item
          key="1"
          style={{
            border: 0,
          }}
        >
          <a href="/#about">About</a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="/#for-who">For Who ?</a>
        </Menu.Item>
        <Menu.Item key="3">
          <a href="/#course-overview">Course Overview</a>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="/#enrollement">Enrollement</a>
        </Menu.Item>
        <Menu.Item key="5">
          <a href="/#faq">FAQ</a>
        </Menu.Item>
      </Menu>
      <Button
        href="/#enrollement"
        className="header-button"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        Start your journey
      </Button>
    </Header>
  )
}

export default HeaderComponent

import React, { useEffect } from 'react'
import { Layout, Row, Col, Typography, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
// @ts-ignore
import { Helmet } from 'react-helmet'
import RadarChart from './Radar'

function QuizzLP() {
  const { Title, Paragraph } = Typography
  const { Content } = Layout
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/quizz/kosmos-quizz')
  }
  useEffect(() => {
    document.title = 'Find your ideal learning path'
  }, [])

  return (
    <Layout style={{ padding: 0, margin: 0 }}>
      <div>
        <Helmet>
          <title>Discover Your Perfect Learning Path in Blockchain</title>
          <meta
            name="description"
            content="Discover Your Perfect Learning Path in Blockchain"
          />
        </Helmet>
        {/* your page content */}
      </div>
      <Content style={{ backgroundColor: '#f9dcc4' }}>
        <Row style={{}}>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={10}
            xl={10}
            xxl={10}
            style={{
              backgroundColor: '#f9dcc4',
              paddingLeft: 50,
              paddingRight: 20,
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 60,
                  }}
                >
                  Discover Your Perfect Learning Path in Blockchain
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: '#d06d66',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Not sure which learning path in blockchain is right for you?
                  Take our quiz to find out! Our quiz will help you identify
                  your goals and interests, and suggest the learning path that's
                  the best fit for you. With four different options to choose
                  from, our quiz will help you to figured out !
                </Paragraph>
              </Col>
            </Row>
            <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
              <Col span={24}>
                <Button
                  onClick={handleClick}
                  className="header-button"
                  style={{
                    backgroundColor: '#d06d66',
                    color: 'white',
                  }}
                >
                  Take the Quizz
                </Button>
              </Col>
            </Row>
            <Row style={{ paddingBottom: 20 }}>
              <Col span={24}>
                <Paragraph
                  style={{
                    fontFamily: "'Ubuntu', sans-serif",
                    color: 'gray',
                    fontSize: 12,
                  }}
                >
                  When clicking on this quiz, you agree on the KosmosChain
                  Privacy Policy
                </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={14}
            lg={14}
            xl={14}
            xxl={14}
            style={{
              backgroundColor: '#eee0d2',
            }}
          >
            <RadarChart />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default QuizzLP

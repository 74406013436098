import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import HeaderComponent from './components/Header'
import HomePage from './pages/HomePage'
import './App.css'
import AppFooter from './components/FooterSection'
import TosPage from './components/TosPage'
// @ts-ignore
import { Helmet } from 'react-helmet'
import PrivacyPage from './components/PrivacyPage'
import KosmosMap from './pages/MapPage'
import QuizzLP from './quizz/QuizzLP'
import Quizz from './quizz/QuizzLearning'
import Sitemap from './components/Sitemap'
import CourseMap from './pages/CoursePage'


const App: React.FC = () => {
  const { Header } = Layout
  return (
    <Router>
      <Header>
        <div>
          <Helmet>
            <title>
              KosmosChain : Blockchain Odyssey - A Journey through the
              Decentralized Kosmos
            </title>
            <meta
              name="description"
              content="KosmosChain : Blockchain Odyssey - A Journey through the Decentralized Kosmos"
            />
          </Helmet>
          {/* your page content */}
        </div>
      </Header>
      <Layout>
        <Row className="active-row">
          <Col span={18}>
            <HeaderComponent />
          </Col>
        </Row>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/terme-of-use" element={<TosPage />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPage />}></Route>
          <Route path="/kosmos" element={<KosmosMap />}></Route>
          <Route path="/kosmos-course" element={<CourseMap />}></Route>
          <Route path="/quizz" element={<QuizzLP />}></Route>
          <Route path="/quizz/kosmos-quizz" element={<Quizz />}></Route>
          <Route path="/sitemap.xml" element={<Sitemap />} />
        </Routes>
        <AppFooter />
      </Layout>
    </Router>
  )
}

export default App

import React from "react";
import { Row, Col, Typography } from "antd";

function FactSection() {
  const { Title, Paragraph } = Typography;
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        minHeight: 500,
      }}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Title
              level={2}
              style={{
                fontFamily: "Ubuntu",
                color: "#055a5b",
              }}
            >
              Not "Just" Numbers
            </Title>
            <Paragraph
              style={{
                fontFamily: "Ubuntu, sans-serif",
                fontSize: 16,
              }}
            >
              As a fellow learner, I've designed it to be a journey that's easy
              to understand and enjoyable to follow. With a story-based approach
              and minimalist, straightforward lessons, you'll gain a solid
              understanding of blockchain and web3. I've focused on keeping
              things simple and engaging, so you can learn without feeling
              overwhelmed or bored. Join me on this learning adventure and
              discover a new world of possibilities.{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: "#B995A1",
                }}
              >
                #BuiltinPublic
              </span>
              <br />
              <br />
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                My mission is to empower 1 million people with the knowledge and
                skills to confidently navigate the world of blockchain
                technology and leverage its potential for personal and
                professional growth.
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#B995A1",
                }}
              >
                #longistheroad
              </span>
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ paddingTop: 20 }}>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#055a5b",
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: 600,
                fontSize: "1.5em",
              }}
            >
              15th of April <br />
              Available on
            </span>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#055a5b",
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: 600,
                fontSize: "1.5em",
              }}
            >
              +600 <br />
              Of Astronaut On board
            </span>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#055a5b",
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: 600,
                fontSize: "1.5em",
              }}
            >
              +60 <br /> Hours of Content
            </span>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            style={{
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#055a5b",
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: 600,
                fontSize: "1.5em",
              }}
            >
              +30 <br /> Module / Stories
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FactSection;

import React from 'react'
import { Row, Col, Typography, Image } from 'antd'

function AboutSection() {
  const { Title, Paragraph } = Typography
  return (
    <Row
      justify="center"
      style={{
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <Col span={24}>
        <Row>
          <Col
            span={24}
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Title
              level={4}
              style={{
                margin: 0,
                fontFamily: 'Ubuntu',
                color: 'white',
                fontWeight: 'lighter',
              }}
            >
              About your pilot
            </Title>
            <Title
              level={1}
              style={{
                margin: 0,
                fontFamily: 'Ubuntu',
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              Into the Kosmos and Beyond
            </Title>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 10,
          }}
        >
          <Col
            span={24}
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                background: 'rgba(188,154,130,0.2)',
                WebkitBackdropFilter: 'blur(9px)',
                backdropFilter: 'blur(9px)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: 10,
              }}
            >
              <Paragraph
                style={{
                  fontFamily: 'Ubuntu, sans-serif',
                  fontSize: 16,
                  color: 'white',
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
              >
                Hello there! My name is Mohammed, and I am honored to be your
                instructor for the Kosmos blockchain course. With years of
                experience working in the blockchain industry, I have developed
                a deep passion for this dynamic and ever-evolving field. <br />{' '}
                <br /> My ultimate goal is to help you gain a comprehensive
                understanding of blockchain technology, from the fundamentals to
                advanced concepts, so that you can confidently apply your
                knowledge to real-world scenarios. <br /> <br />
                Allow me to tell you a little bit about myself! I am first and
                foremost passionate about breaking down complex knowledge and
                presenting it in a clear, easy-to-understand manner using
                unconventional metaphors and playful examples. As a programmer
                for over 20 years, I have taught technology for three years,
                co-founded two companies (one in AI and another in Fintech),
                served as CTO for two corporations, and provided tech consulting
                for startups and established brands such as LMVH, Renault,
                Nissan, and La Poste.
                <br /> <br />I am excited to bring my diverse experience and
                expertise to this course, and I look forward to sharing this
                journey with you!
              </Paragraph>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 20,
          }}
        >
          <Col
            span={24}
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Image
              src="../assets/pilot.jpg"
              alt="Your instructor"
              height={150}
              preview={false}
              style={{
                borderRadius: 20,
              }}
            ></Image>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default AboutSection

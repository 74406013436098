import React, { useState } from "react";
import { CompassFilled } from "@ant-design/icons";

import { Card, Typography, Grid } from "antd";

interface ProfileCardProp {
  title: string;
  headline: string;
  description: string;
  level: string;
}

function ProfileCard({ title, description, headline, level }: ProfileCardProp) {
  const { Meta } = Card;
  const [hovered, setHovered] = useState(false);
  const { Paragraph } = Typography;
  const { useBreakpoint } = Grid;
  const breakpoint = useBreakpoint();
  const anyBreakpoint =
    breakpoint.xl || breakpoint.xxl || breakpoint.lg || breakpoint.md
      ? true
      : false;
  return (
    <Card
      hoverable
      title={title}
      className="profile"
      headStyle={{
        textAlign: "left",
        backgroundColor: "#f9dcc4",
        borderRadius: 0,
        fontFamily: "Ubuntu",
        fontWeight: "bold",
      }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "300px",
      }}
      style={{
        width: anyBreakpoint ? 320 : 300,
        maxHeight: 400,
        minHeight: 410,
        borderRadius: 0,
      }}
      actions={[
        <div
          style={{
            textAlign: "left",
            color: "#606c38",
            paddingLeft: 20,
          }}
        >
          <CompassFilled key="setting" />
          <span style={{ marginLeft: 5 }}>{level}</span>
        </div>,
      ]}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {!hovered && (
        <Meta
          style={{
            textAlign: "left",
            height: "100%",
            whiteSpace: "normal",
          }}
          title={<div style={{ whiteSpace: "normal" }}>{headline}</div>}
          description={
            <Paragraph
              ellipsis={{
                rows: 3,
                expandable: true,

                symbol: "more",
              }}
            >
              {" "}
              {description}{" "}
            </Paragraph>
          }
        />
      )}
      {hovered && (
        <img
          alt={title}
          width="100%"
          height="100%"
          src={"../assets/".concat(title, ".png")}
          style={{
            borderRadius: 10,
            maxHeight: 400,
          }}
        />
      )}
    </Card>
  );
}
export default ProfileCard;

import React, { useState } from "react";

import { Layout, Row, Col, Space, Button } from "antd";

import * as echarts from "echarts/core";
import { TreemapChart } from "echarts/charts";
import { TitleComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import vintage from "../vintage.json";
import { MyTreeMap } from "../components/Charts";
import { kosmosCourse, storyOverview } from "../data/Course";
// Register the necessary components
echarts.use([TitleComponent, TooltipComponent, TreemapChart, CanvasRenderer]);
// load theme data
echarts.registerTheme("vintage", vintage);

const CourseMap = () => {
  const [view, setView] = useState("module");
  const { Content } = Layout;
  return (
    <React.Fragment>
      <Layout>
        <Content>
          <Row
            style={{ backgroundColor: "#fef8ef", paddingTop: 10 }}
            justify="center"
          >
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <Space>
                <Button
                  className="header-button"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  onClick={() => setView("module")}
                >
                  Module View
                </Button>
                <Button
                  className="header-button"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  onClick={() => setView("story")}
                >
                  Story View
                </Button>
              </Space>
            </Col>
          </Row>
          <Row
            style={{ backgroundColor: "#fef8ef" }}
            justify="center"
            align="middle"
          >
            <Col
              span={24}
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  height: "80vh",
                  width: "100%",
                  backgroundColor: "#fef8ef",
                }}
              >
                {view === "module" && (
                  <MyTreeMap
                    title="Kosmos - Course Overview"
                    blockchainMapData={kosmosCourse}
                  />
                )}
                {view === "story" && (
                  <MyTreeMap
                    title="Kosmos - A Journey through the Decentralized Kosmos"
                    blockchainMapData={storyOverview}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{ backgroundColor: "#fef8ef" }}>
            <Col span={16}>
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                Welcome to our Kosmos online course! We have organized the
                course modules in a treemap to help you navigate the content
                easily and efficiently.
              </p>

              <p
                style={{
                  fontSize: "18px",
                }}
              >
                The treemap is divided into rectangular boxes, with each box
                representing a course module. The size of the box corresponds to
                the relative weight of the module in the course, and the color
                indicates the topic or theme of the module. The treemap layout
                makes it easy to see the overall structure of the course at a
                glance, and to identify the most important topics and themes.
              </p>

              <p
                style={{
                  fontSize: "18px",
                }}
              >
                I hope that this treemap provides a useful resource for anyone
                looking to gain a deeper understanding of the blockchain world.
                Whether you are a blockchain enthusiast or a curious newcomer.
              </p>

              <p
                style={{
                  fontSize: "18px",
                }}
              >
                To explore the course content, simply click on a box to zoom in
                and see the chapters and sub-chapters of the module. You can
                navigate back to the previous level of the treemap by clicking
                on the back button or by using the breadcrumbs at the top of the
                page.
              </p>
            </Col>
          </Row>
        </Content>
      </Layout>
    </React.Fragment>
  );
};

export default CourseMap;

import React from "react";
import { Row, Col, Typography, Space, Button } from "antd";
import "../App.css";
import ModuleCard from "./ModuleCard";
import { useNavigate } from "react-router-dom";

function OverviewSection() {
  const { Title, Paragraph } = Typography;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/kosmos-course/");
  };
  return (
    <Row
      justify="center"
      style={{
        paddingTop: 20,
        minHeight: 600,
      }}
    >
      <Col span={24}>
        <Row>
          <Col
            span={24}
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={4}
                  style={{
                    margin: 0,
                    fontFamily: "Ubuntu",
                    color: "#055a5b",
                    fontWeight: "lighter",
                  }}
                >
                  Course Overview
                </Title>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    margin: 0,
                    fontFamily: "Ubuntu",
                    color: "#055a5b",
                    fontWeight: "bold",
                  }}
                >
                  Kosmos Online Course
                </Title>
              </Col>
            </Row>

            <Row
              style={{
                paddingTop: 20,
              }}
            >
              <Col span={24}>
                <Paragraph>
                  Think of each module in the Kosmos blockchain course as a
                  journey through the cosmos, led by a renowned scientist who
                  has made a lasting impact on our world. Each scientist's name
                  is carefully selected to inspire and motivate you to make a
                  positive impact on others through technology.
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 50,
          }}
          justify="center"
        >
          <Space size="large" className="overview-overflow">
            <Col>
              <ModuleCard
                title="Chapter 1- Aristotle"
                description="This space is for understanding concepts, ideas, relationships, and getting an overview of the Web3 and Blockchain world."
                img="aristotle"
              />
            </Col>
            <Col>
              <ModuleCard
                title="Chapter 2- Archimedes"
                description="In this section, we will use your previous learning to help you build a strong foundation and empower you to create your own spacecraft and explore the Kosmos."
                img="Archimedes"
              />
            </Col>

            <Col>
              <ModuleCard
                title="Chapter 3- M.Hamilton"
                description="Let's clarify some of the mysteries surrounding the computer science world, such as backend, frontend, APIs, web development, mobile development, and more."
                img="margaret"
              />
            </Col>

            <Col>
              <ModuleCard
                title="Chapter 4- Marie Curie"
                description="Create an upgraded version of your city (Blockchain project) from scratch and make it your own in this world-building adventure."
                img="marie"
              />
            </Col>
            <Col>
              <ModuleCard
                title="More ..."
                description="More stories and modules to take you into a wonderful Kosmos Journey"
                img="other"
              />
            </Col>
          </Space>
        </Row>
        <Row
          style={{
            paddingTop: 30,
          }}
        >
          <Col
            span={24}
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Button
              className="header-button"
              onClick={handleClick}
              size="large"
              style={{
                backgroundColor: "#055a5b",
                color: "white",
                fontFamily: "'Ubuntu', sans-serif",
                fontWeight: 700,
                borderRadius: 0,
              }}
            >
              Explore The Kosmos
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default OverviewSection;

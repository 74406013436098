import React from "react";
import { Layout, Row, Col } from "antd";
import "../App.css";
import HeroSection from "../components/HeroSection";
import WhoSection from "../components/ForWhoSection";
import QuizzSection from "../components/QuizzSection";
import FactSection from "../components/FactSection";
import OverviewSection from "../components/OverviewSection";
import AboutSection from "../components/AboutSection";
import PricingSection from "../components/PricingSection";
import FAQSection from "../components/FAQSection";

function HomePage() {
  const { Content } = Layout;
  return (
    <Layout>
      <Content>
        <Row id="about" className="active-row">
          <Col span={18}>
            <HeroSection />
          </Col>
        </Row>
        <Row id="for-who" className="dark-row">
          <Col span={18}>
            <WhoSection />
          </Col>
        </Row>
        <Row className="black-row">
          <Col span={18}>
            <QuizzSection />
          </Col>
        </Row>
        <Row className="lighter-row">
          <Col span={18}>
            <FactSection />
          </Col>
        </Row>
        <Row id="course-overview" className="active-row">
          <Col span={18}>
            <OverviewSection />
          </Col>
        </Row>
        <Row className="dark-row">
          <Col span={18}>
            <AboutSection />
          </Col>
        </Row>
        <Row id="enrollement" className="lighter-row">
          <Col span={18}>
            <PricingSection />
          </Col>
        </Row>
        <Row id="faq" className="active-row">
          <Col span={18}>
            <FAQSection />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default HomePage;

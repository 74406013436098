import React from 'react'
import { Row, Col, Typography } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, theme } from 'antd'

import '../App.css'

function FAQSection() {
  const { Panel } = Collapse
  const { Title, Paragraph } = Typography

  const { token } = theme.useToken()

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: '1px solid black',
  }
  return (
    <Row
      justify="center"
      style={{
        paddingTop: 20,
      }}
    >
      <Col span={24}>
        <Row>
          <Col
            span={24}
            style={{
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Row>
              <Col span={24}>
                <Title
                  level={4}
                  style={{
                    margin: 0,
                    fontFamily: 'Ubuntu',
                    color: '#055a5b',
                    fontWeight: 'lighter',
                  }}
                >
                  Still not sure ?
                </Title>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Title
                  level={1}
                  style={{
                    margin: 0,
                    fontFamily: 'Ubuntu',
                    color: '#055a5b',
                    fontWeight: 'bold',
                  }}
                >
                  FAQ
                </Title>
              </Col>
            </Row>

            <Row
              style={{
                paddingTop: 20,
              }}
            >
              <Col span={24}>
                <Paragraph>
                  If you have any further inquiries or need more information,
                  please don't hesitate to reach out to us at
                  pilot@kosmoschain.com. We are always happy to assist and look
                  forward to hearing from you!
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Collapse
              bordered={false}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{ background: 'transparent' }}
            >
              <Panel
                header="Course Format and Duration"
                key="0"
                style={panelStyle}
              >
                <p>
                  Course Format: The course is delivered entirely online and consists of video lectures, interactive quizzes, and hands-on assignments that will help learners gain practical experience in blockchain and web3.

</p><p>Course Duration: The course is self-paced, and learners can complete it in their own time. The course materials will be available to learners for 12 months from the date of enrollment, providing ample time to complete the course.
                </p>
              </Panel>
              <Panel
                header="What makes this course different from other blockchain courses?"
                key="1"
                style={panelStyle}
              >
                <p>
                  Kosmos blockchain course is designed to provide a
                  comprehensive and in-depth understanding of blockchain
                  technology, from the basics to an expert level, while using
                  unconventional metaphors and playful examples to make complex
                  concepts more accessible and engaging.
                </p>
              </Panel>
              <Panel
                header="Can I access the course content after I complete the program?"
                key="2"
                style={panelStyle}
              >
                <p>
                  Yes, you will have lifetime access to the course content after
                  you complete the program. You can revisit the lessons and
                  review the materials at any time, even after the course has
                  ended.
                </p>
              </Panel>
              <Panel
                header="Is this course suitable for beginners or do I need prior experience with blockchain?"
                key="3"
                style={panelStyle}
              >
                <p>
                  Our course is suitable for both beginners and those with prior
                  experience in blockchain. We cover the fundamentals of
                  blockchain technology and gradually move towards more advanced
                  concepts, making the course accessible to students with
                  varying levels of expertise.
                </p>
              </Panel>
              <Panel
                header="Are there any prerequisites for this course?"
                key="4"
                style={panelStyle}
              >
                <p>
                  Depend on the learning path you choose. I recommend basic
                  knowledge on programming for Hero Learning path.
                  <br />
                  For the others learning paths there are no prerequisites. We
                  recommend that students have an interest in blockchain, but no
                  prior knowledge is necessary.
                </p>
              </Panel>
              <Panel
                header="How much time will I need to invest in the course each week?"
                key="5"
                style={panelStyle}
              >
                <p>
                  The course is designed to be flexible and self-paced, so the
                  amount of time you need to invest each week will depend on
                  your schedule and learning style. We recommend dedicating at
                  least 5 hours per week to the course to ensure that you get
                  the most out of it.
                </p>
              </Panel>
              <Panel
                header="Can I get a refund if I'm not satisfied with the course?"
                key="6"
                style={panelStyle}
              >
                <p>
                  Yes, just send me an email to pilot@kosmoschain.com with
                  subject REFUND.
                </p>
              </Panel>
              <Panel
                header="Will I have access to any additional resources, such as online forums or study groups?"
                key="7"
                style={panelStyle}
              >
                <p>
                  Yes, discord or discourse (still in reflexion) online
                  community forum, where you can interact with other students,
                  exchange ideas, and share your experience.
                </p>
              </Panel>
              <Panel
                header="Any special pricing for Teams / Companies ?"
                key="8"
                style={panelStyle}
              >
                <p>Yes, reach out to: copilot@kosmoschain.com</p>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FAQSection

import { Question, Answer } from './Quizz'

export const questions = [
  new Question({
    id: 1,
    question:
      'What is your level of experience with blockchain?',
    order: 1,
    answers: [
      new Answer({
        id: 1,
        answer: 'Beginner',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 2,
        answer: 'Intermediate',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 3,
        answer: 'Advanced',
        tags: ['Hero'],
      }),
      new Answer({
        id: 4,
        answer: 'Expert',
        tags: ['Hero'],
      }),
    ],
  }),
  new Question({
    id: 2,
    question:
      'What is your main goal in learning about blockchain?',
    order: 1,
    answers: [
      new Answer({
        id: 5,
        answer: 'Gain a foundational understanding',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 6,
        answer: 'Understand the potential of blockchain for different industries',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 7,
        answer: 'Build blockchain-powered products and services',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 8,
        answer: 'Master blockchain development',
        tags: ['Hero'],
      }),
    ],
    
  }),
  new Question({
    id: 3,
    question:
      'What is your profession?',
    order: 1,
    answers: [
      new Answer({
        id: 9,
        answer: 'Student',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 10,
        answer: 'Business professional',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 11,
        answer: 'Entrepreneur',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 12,
        answer: 'Software engineer/developer',
        tags: ['Hero'],
      }),
    ],
  }),
  new Question({
    id: 4,
    question:
      'How much time can you dedicate to learning about blockchain?',
    order: 1,
    answers: [
      new Answer({
        id: 13,
        answer: 'Less than 1 hour per week',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 14,
        answer: '1-2 hours per week',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 15,
        answer: '3-5 hours per week',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 16,
        answer: 'More than 5 hours per week',
        tags: ['Hero'],
      }),
    ],
  }),
  new Question({
    id: 5,
    question:
      'Which of the following best describes your technical background?',
    order: 1,
    answers: [
      new Answer({
        id: 17,
        answer: 'Limited',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 18,
        answer: 'Basic understanding',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 19,
        answer: 'Comfortable with technology',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 20,
        answer: 'Advanced',
        tags: ['Hero'],
      }),
    ],
  }),new Question({
    id: 6,
    question:
      'What industry are you most interested in exploring the potential of blockchain for?',
    order: 1,
    answers: [
      new Answer({
        id: 17,
        answer: 'Finance',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 18,
        answer: 'Supply chain',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 19,
        answer: 'Healthcare',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 20,
        answer: 'Gaming',
        tags: ['Hero'],
      }),new Answer({
        id: 21,
        answer: 'Other',
        tags: [''],
      }),
    ],
  }),
  new Question({
    id: 7,
    question:
      'What is your preferred learning style?',
    order: 1,
    answers: [
      new Answer({
        id: 21,
        answer: 'Visual',
        tags: ['Adventurer'],
      }),
      new Answer({
        id: 22,
        answer: 'Auditory',
        tags: ['Mediator'],
      }),
      new Answer({
        id: 23,
        answer: 'Hands-on',
        tags: ['Virtuoso'],
      }),
      new Answer({
        id: 24,
        answer: 'Reading/writing',
        tags: ['Hero'],
      }),
    ],
  }),
]

export function findMostCommonElement<T>(arr: T[]): T | undefined {
  const freqMap = new Map<T, number>()
  let maxFreq = 0
  let maxFreqElements: T[] = []

  // Count frequency of each element
  arr.forEach((elem) => {
    const freq = (freqMap.get(elem) ?? 0) + 1
    freqMap.set(elem, freq)

    if (freq > maxFreq) {
      maxFreq = freq
      maxFreqElements = [elem]
    } else if (freq === maxFreq) {
      maxFreqElements.push(elem)
    }
  })

  // Return one of the most common elements at random
  return maxFreqElements[Math.floor(Math.random() * maxFreqElements.length)]
}
